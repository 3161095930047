function setupGoogleAnalytics() {
  // eslint-disable-next-line no-underscore-dangle, no-undef
  window._gaq = window._gaq || []
  // eslint-disable-next-line no-undef
  _gaq.push(['_setAccount', 'UA-18018358-1'])
  // eslint-disable-next-line no-undef
  _gaq.push(['_trackPageview']);

  // eslint-disable-next-line func-names
  (function () {
    const ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true
    ga.src = `${document.location.protocol === 'https:' ? 'https://ssl' : 'http://www'}.google-analytics.com/ga.js`
    const s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s)
  }())
}

window.cookieConsent.addCallback('performance', setupGoogleAnalytics)
